/* eslint-disable i18next/no-literal-string */
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useProvideAppState } from "../utils/stateUtils";
import "../i18n/config";
import { VerticalViewContainer } from "../components/ViewContainer";
import { QueryClient, QueryClientProvider } from "react-query";
import { SuperAdminContainer } from "./SuperAdmin/SuperAdminContainer";
import { licenseAgGrid } from "../licenseAgGrid";
import { SplitConfiguration } from "../split/SplitConfiguration";
import { CurrentUserProvider } from "../auth/CurrentUserProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import { DatadogRumProvider } from "../datadog/DatadogRumProvider";
import { DatadogLoggerProvider } from "../datadog/DatadogLoggerProvider";
import { AdminUsersSettings } from "./Admin/Settings/AdminUsersSettings";
import { OrganizationSettings } from "./Admin/Settings/OrganizationSettings";
import { SettingsContainer } from "../components/SettingsContainer";
import { PostHogWrapper } from "../posthog/PostHogWrapper";
import { LanguageProvider } from "../localize/LanguageProvider";
import { CrowdInEditor } from "../crowdin/CrowdInEditor";
import { FeatureFlags } from "../split/FeatureFlags";
import { FeatureFlagContainer } from "../split/FeatureFlagContainer";
import { OrganizationCurrencySettings } from "./Admin/Settings/OrganizationCurrencySettings/OrganizationCurrencySettings";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

licenseAgGrid();

/**
 * Root component of the TypeScript app.
 *
 * Responsible for:
 * - Calling hooks that set up app state
 * - Routing
 */
export const App_ = () => {
  const routes = [
    {
      path: "/new/superadmin",
      element: <SuperAdminContainer />,
    },
  ];

  const routesWithSettingsContainer = [
    {
      path: "/new/settings/organization",
      element: <OrganizationSettings />,
    },
    {
      path: "/new/settings/users",
      element: <AdminUsersSettings />,
    },
    {
      path: "/new/settings/currency",
      element: <OrganizationCurrencySettings />,
    },
  ];

  return (
    <main className="h-screen w-screen flex flex-col">
      <Routes>
        {routes.map(({ path, element }, index) => (
          <Route
            key={"route-" + index}
            path={path}
            element={<VerticalViewContainer>{element}</VerticalViewContainer>}
          />
        ))}
        {routesWithSettingsContainer.map(({ path, element }, index) => (
          <Route
            key={"settingsRoute-" + index}
            path={path}
            element={<SettingsContainer>{element}</SettingsContainer>}
          />
        ))}
        <Route path="/*" element={<NoMatch />} />
      </Routes>
    </main>
  );
};

/**
 * Proveide react contexts and similar global state needed.
 *
 * This is used to wrap the purescript app, which in turn wraps the
 * typescript one so we can run typescript components on both sides.
 */
export function AppWrapper<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  return function AppWrapper_(props: P) {
    // Provides our app state and subscribes to worker state updates
    useProvideAppState();

    return (
      <CurrentUserProvider>
        <DatadogRumProvider>
          <DatadogLoggerProvider>
            <SplitConfiguration>
              <QueryClientProvider client={queryClient}>
                <PostHogWrapper>
                  <LanguageProvider>
                    <BrowserRouter>
                      <FeatureFlagContainer feature={FeatureFlags.CROWDIN}>
                        <CrowdInEditor />
                      </FeatureFlagContainer>
                      <WrappedComponent {...props} />
                    </BrowserRouter>
                  </LanguageProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </PostHogWrapper>
              </QueryClientProvider>
            </SplitConfiguration>
          </DatadogLoggerProvider>
        </DatadogRumProvider>
      </CurrentUserProvider>
    );
  };
}

/**
 * Wrap the real App component to workaround PureScript code not rendering this
 * correctly as a React component.
 *
 * This can eventually be thrown out once we're responsible of rendering
 * the actual React tree ourselves.
 */
export const App = () => {
  return <App_ />;
};

const NoMatch = () => {
  const location = useLocation();

  return (
    <div>
      <h3 className="m-4 text-xl">
        No match for route <code>{location.pathname}</code>
      </h3>
      <div className="m-4">
        The new React TypeScript app can be found under{" "}
        <a href="/new" className="text-primary underline">
          /new
        </a>
      </div>
    </div>
  );
};
