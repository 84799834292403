// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoList = require("../TacoList/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Classification = require("../Types.Classification/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseWorkerData = require("../UseWorkerData/index.js");
var ColProjectClassificationClassCode = (function () {
    function ColProjectClassificationClassCode() {

    };
    ColProjectClassificationClassCode.value = new ColProjectClassificationClassCode();
    return ColProjectClassificationClassCode;
})();
var ColProjectClassificationClassDescription = (function () {
    function ColProjectClassificationClassDescription() {

    };
    ColProjectClassificationClassDescription.value = new ColProjectClassificationClassDescription();
    return ColProjectClassificationClassDescription;
})();
var ColProjectClassificationClassActionButton = (function () {
    function ColProjectClassificationClassActionButton() {

    };
    ColProjectClassificationClassActionButton.value = new ColProjectClassificationClassActionButton();
    return ColProjectClassificationClassActionButton;
})();
var projectClassificationClassTreeModalStyleSet = TacoTable_Styles.foldingTableStyleSetWithoutSticky;
var projectClassificationClassTreeModalColumns = function (fn) {
    return [ {
        key: "code",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text("Koodi"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColProjectClassificationClassCode.value),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "description",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text("Selite"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColProjectClassificationClassDescription.value),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "actionButton",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text(""),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColProjectClassificationClassActionButton.value),
        headerJSX: Data_Maybe.Nothing.value
    } ];
};
var projectClassificationClassesTreeModal = TofuHooks.mkMemoHookComponent("ProjectClassificationClassesTreeModal")(function (props) {
    var defaultNewProjectClassificationClassInputs = {
        code: "",
        description: ""
    };
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(defaultNewProjectClassificationClassInputs)();
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var newProjectClassificationClassSubmitButton = TacoButton.component()()({
            text: "Luo uusi nimikkeist\xf6ryhm\xe4",
            disabled: Data_String_Common["null"](v.value0.code) || Data_String_Common["null"](v.value0.description),
            onClick: new Data_Maybe.Just(dispatch(new Actions.ProjectClassificationClassActionRequest({
                handler: function (v2) {
                    return v.value1(function (v3) {
                        return defaultNewProjectClassificationClassInputs;
                    });
                },
                projectId: props.projectId,
                params: new Types_Classification.AddProjectClassificationClass({
                    code: v.value0.code,
                    description: v.value0.description
                })
            })))
        });
        var newProjectClassificationClassDescription = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v.value0.description,
            onChange: function (fn) {
                return v.value1(function (r) {
                    return {
                        code: r.code,
                        description: fn(r.description)
                    };
                });
            },
            validator: function (s) {
                var $51 = Data_String_CodePoints.length(s) <= 50;
                if ($51) {
                    return TacoInput.Valid.value;
                };
                return new TacoInput.Invalid("");
            }
        });
        var newProjectClassificationClassCode = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v.value0.code,
            onChange: function (fn) {
                return v.value1(function (r) {
                    return {
                        code: fn(r.code),
                        description: r.description
                    };
                });
            },
            validator: function (s) {
                var $52 = Data_String_CodePoints.length(s) <= 20 && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Data_Ord.ordString))(s)(props.existingProjectClassificationClassCodes);
                if ($52) {
                    return TacoInput.Valid.value;
                };
                return new TacoInput.Invalid("");
            }
        });
        var newProjectClassificationClass = TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString)))({
            rowData: v.value0,
            css: React_Basic_Emotion.css()({}),
            rowHeight: TacoTable_Types.RowMedium.value,
            sortProperty: TacoTable_Types.noSortProperty,
            onClick: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            className: "",
            foldingElement: Data_Maybe.Nothing.value,
            styleSet: projectClassificationClassTreeModalStyleSet.rowStyleSet,
            style: React_Basic_DOM_Internal.css({}),
            columns: projectClassificationClassTreeModalColumns(function (v2) {
                if (v2 instanceof ColProjectClassificationClassCode) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newProjectClassificationClassCode;
                    });
                };
                if (v2 instanceof ColProjectClassificationClassDescription) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newProjectClassificationClassDescription;
                    });
                };
                if (v2 instanceof ColProjectClassificationClassActionButton) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newProjectClassificationClassSubmitButton;
                    });
                };
                throw new Error("Failed pattern match at ProjectClassificationTable (line 355, column 61 - line 358, column 121): " + [ v2.constructor.name ]);
            }),
            rowKey: "new-project-classification-class-row"
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(props.onClose),
                text: "Peruuta",
                testId: "project-classification-table-cancel-button"
            }) ]
        });
        var mkAction$prime = function (fn) {
            return function (value) {
                return function (handler) {
                    return new Actions.ProjectClassificationClassActionRequest({
                        handler: handler,
                        projectId: props.projectId,
                        params: Types_Classification.EditProjectClassificationClass.create(fn(value))
                    });
                };
            };
        };
        var projectClassificationClassColumns = projectClassificationClassTreeModalColumns(function (v2) {
            if (v2 instanceof ColProjectClassificationClassCode) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                    return TacoInput.remoteStringField()()({
                        value: r.projectClassificationClass.code,
                        mkAction: mkAction$prime(function (value) {
                            return {
                                id: r.projectClassificationClass.id,
                                code: value,
                                description: r.projectClassificationClass.description
                            };
                        }),
                        validator: function (s) {
                            var $56 = Data_String_CodePoints.length(s) <= 20 && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Data_Ord.ordString))(s)(props.existingProjectClassificationClassCodes) || s === r.projectClassificationClass.code;
                            if ($56) {
                                return TacoInput.Valid.value;
                            };
                            return new TacoInput.Invalid("");
                        },
                        disabled: r.isNonEmpty,
                        title: Data_Monoid.guard(Data_Monoid.monoidString)(r.isNonEmpty)("Koodia ei voi muokata, jos nimikkeist\xf6ryhm\xe4ll\xe4 on suoritteita."),
                        testId: "classification-class-code-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.projectClassificationClass.id)) + "-input")
                    });
                });
            };
            if (v2 instanceof ColProjectClassificationClassDescription) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                    return TacoInput.remoteStringField()()({
                        value: r.projectClassificationClass.description,
                        mkAction: mkAction$prime(function (value) {
                            return {
                                id: r.projectClassificationClass.id,
                                code: r.projectClassificationClass.code,
                                description: value
                            };
                        }),
                        validator: function (s) {
                            var $58 = Data_String_CodePoints.length(s) <= 50;
                            if ($58) {
                                return TacoInput.Valid.value;
                            };
                            return new TacoInput.Invalid("");
                        },
                        testId: "classification-class-description-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.projectClassificationClass.id)) + "-input")
                    });
                });
            };
            if (v2 instanceof ColProjectClassificationClassActionButton) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                    return TacoButton.component()()({
                        text: "Poista",
                        color: TacoConstants.SecondaryRed.value,
                        disabled: r.isNonEmpty,
                        title: "Vain nimikkeist\xf6ryhm\xe4n, jolla ei ole suoritteita, voi poistaa.",
                        onClick: new Data_Maybe.Just(v1.value1(function (v3) {
                            return new Data_Maybe.Just(r);
                        })),
                        testId: "classification-class-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.projectClassificationClass.id)) + "-action-button")
                    });
                });
            };
            throw new Error("Failed pattern match at ProjectClassificationTable (line 280, column 84 - line 311, column 12): " + [ v2.constructor.name ]);
        });
        var mkProjectClassificationClass = function (projectClassificationClass) {
            var div$prime$prime = function (x) {
                return React_Basic_DOM_Generated.div()({
                    key: Data_Show.show(Types_Newtypes.showProjectClassificationClassId)(projectClassificationClass.id),
                    children: [ x ]
                });
            };
            return div$prime$prime(TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "projectClassificationClass";
                }
            })(Types_Classification.eqProjectClassificationClass))()({
                reflectSymbol: function () {
                    return "isNonEmpty";
                }
            })(Data_Eq.eqBoolean)))({
                rowData: {
                    projectClassificationClass: projectClassificationClass,
                    isNonEmpty: Data_Set.member(Data_Ord.ordString)(projectClassificationClass.code)(props.nonEmptyProjectClassificationClassCodes)
                },
                css: React_Basic_Emotion.css()({}),
                rowHeight: TacoTable_Types.RowLarge.value,
                sortProperty: TacoTable_Types.noSortProperty,
                onClick: function (v2) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                styleSet: projectClassificationClassTreeModalStyleSet.rowStyleSet,
                style: React_Basic_DOM_Internal.css({}),
                columns: projectClassificationClassColumns,
                rowKey: "project-classification-class-" + Data_Show.show(Types_Newtypes.showProjectClassificationClassId)(projectClassificationClass.id)
            }));
        };
        var newHeader = TacoTable_Header.header(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "projectClassificationClass";
            }
        })(Types_Classification.eqProjectClassificationClass))()({
            reflectSymbol: function () {
                return "isNonEmpty";
            }
        })(Data_Eq.eqBoolean)))({
            sortProperty: TacoTable_Types.noSortProperty,
            sortingOrder: TacoTable_Types.Ascending.value,
            styleSet: projectClassificationClassTreeModalStyleSet.headerStyleSet,
            onSort: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            foldingElement: Data_Maybe.Nothing.value,
            columns: projectClassificationClassColumns,
            onResize: function (v2) {
                return function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value,
            scrollLeft: 0.0
        });
        var deletePromptModalBody = TacoModal.modalBody({
            contents: (function () {
                if (v1.value0 instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v1.value0 instanceof Data_Maybe.Just) {
                    return Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                        text: "Haluatko varmasti poistaa nimikkeist\xf6ryhm\xe4n " + (v1.value0.value0.projectClassificationClass.code + (" \"" + (v1.value0.value0.projectClassificationClass.description + "\"")))
                    }));
                };
                throw new Error("Failed pattern match at ProjectClassificationTable (line 229, column 19 - line 233, column 115): " + [ v1.value0.constructor.name ]);
            })()
        });
        var deletePromptModalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v1.value1(function (v2) {
                    return Data_Maybe.Nothing.value;
                })),
                text: "Peruuta",
                testId: "cancel-delete-project-classification-class-button"
            }), TacoButton.component()()({
                onClick: (function () {
                    if (v1.value0 instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v1.value0 instanceof Data_Maybe.Just) {
                        return new Data_Maybe.Just(dispatch(new Actions.ProjectClassificationClassActionRequest({
                            handler: function (v2) {
                                return v1.value1(function (v3) {
                                    return Data_Maybe.Nothing.value;
                                });
                            },
                            projectId: props.projectId,
                            params: new Types_Classification.DeleteProjectClassificationClass({
                                projectClassificationClassId: v1.value0.value0.projectClassificationClass.id
                            })
                        })));
                    };
                    throw new Error("Failed pattern match at ProjectClassificationTable (line 244, column 26 - line 253, column 26): " + [ v1.value0.constructor.name ]);
                })(),
                text: "Poista",
                color: TacoConstants.SecondaryRed.value,
                testId: "submit-delete-project-classification-class-button"
            }) ]
        });
        var deletePromptModal = TacoModal.component()()({
            onClose: v1.value1(function (v2) {
                return Data_Maybe.Nothing.value;
            }),
            isActive: Data_Maybe.isJust(v1.value0),
            heading: "Poista nimikkeist\xf6ryhm\xe4",
            contents: [ deletePromptModalBody, deletePromptModalActions ]
        });
        var byCode = function (v2) {
            return v2.code;
        };
        var projectClassificationClasses = Data_Functor.map(Data_Functor.functorArray)(mkProjectClassificationClass)(Data_Array.sortWith(Data_Ord.ordString)(byCode)(props.projectClassificationClasses));
        var modalBody = TacoModal.modalBody({
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(Box.box("__TacoTable")([ Box.Width.create(new TacoConstants.CustomSize("100%")) ])([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "table-rows",
                css: Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")) ]),
                children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ newHeader ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(projectClassificationClasses)([ newProjectClassificationClass ]))
            }), deletePromptModal ]))
        });
        return TacoModal.component()()({
            onClose: props.onClose,
            isActive: props.isActive,
            heading: "Muokkaa nimikkeist\xf6\xe4",
            contents: [ modalBody, modalActions ]
        });
    };
});
var projectClassificationTable = TofuHooks.mkMemoHookComponent("ProjectClassificationTable")(function (props) {
    return function __do() {
        var v = Client_WASM.useProjectClassificationClasses(props.projectId)();
        var v1 = Client_WASM.useComponentsWithResources(props.projectId)();
        var v2 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v2) {
            return v2.workerProjectClassificationDataState;
        })("ProjectClassificationDataState")();
        var v3 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v3) {
            return v3.workerUnfilteredSepProjectClassificationDataState;
        })("UnfilteredSepProjectClassificationDataState")();
        var loadingClassificationData = v2.value1 || v3.value1;
        var existingProjectClassificationClassCodes$prime = ReactHooksUtils.usePersistMemo("ProjectClassificationClass_existingProjectCostClasses")([ ReactHooksUtils.utf(v.value0) ])(function (v4) {
            return Data_Functor.map(Data_Functor.functorArray)(function (v5) {
                return v5.code;
            })(v.value0);
        })();
        var existingProjectClassificationClassCodes = TofuHooks.useMemo([ ReactHooksUtils.utf(existingProjectClassificationClassCodes$prime) ])(function (v4) {
            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordString)(existingProjectClassificationClassCodes$prime);
        })();
        var nonEmptyProjectClassificationClassCodes = ReactHooksUtils.usePersistMemo("ProjectClassificationClass_nonEmptyProjectCostClasses")([ ReactHooksUtils.utf(existingProjectClassificationClassCodes$prime), ReactHooksUtils.utf(v1.value0) ])(function (v4) {
            var components = Data_Functor.map(Data_Functor.functorArray)(function (v5) {
                return v5.component;
            })(v1.value0);
            var componentCodes = Data_Array.mapMaybe(function ($103) {
                return Data_Nullable.toMaybe((function (v5) {
                    return v5.code;
                })($103));
            })(components);
            var isPrefix = function (s) {
                return Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(StringUtils.startsWith(s))(componentCodes);
            };
            var nonEmptyCodes = Data_Array.filter(isPrefix)(existingProjectClassificationClassCodes$prime);
            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordString)(nonEmptyCodes);
        })();
        var currencySymbol = EstimationUtils.getCurrencySymbol(props.project.currency);
        var v4 = TofuHooks.useState(false)();
        var v5 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("project-classification-table-show-contents-" + Data_Show.show(Types_Project.showProjectId)(props.projectId))(true)(false)();
        var prepareSubItem = TofuHooks.useMemo([ ReactHooksUtils.utf(props.toggleCodeFilter) ])(function (v6) {
            return function (v7) {
                var title = v7.code + (" " + v7.description);
                return {
                    title: title,
                    titleJSX: Data_Maybe.Nothing.value,
                    id: title,
                    value: TacoList.TextValue.create(Formatters.formatDecimalValue(2)(v7.total) + (" " + currencySymbol)),
                    disabled: v7.total === 0.0,
                    onClick: Data_Maybe.Just.create(props.toggleCodeFilter(v7.code + "%")),
                    getItems: function (v8) {
                        return [  ];
                    },
                    hasItems: false,
                    initExpanded: false
                };
            };
        })();
        var prepareItem = TofuHooks.useMemo([ ReactHooksUtils.utf(prepareSubItem), ReactHooksUtils.utf(props.toggleCodeFilter) ])(function (v6) {
            return function (v7) {
                var title = v7.code + (" " + v7.description);
                return {
                    title: "",
                    titleJSX: Data_Maybe.Just.create(React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(title) ])),
                    id: title,
                    value: TacoList.TextValue.create(Formatters.formatDecimalValue(2)(v7.total) + (" " + currencySymbol)),
                    onClick: new Data_Maybe.Just((function () {
                        var $82 = v7.code === "Muu";
                        if ($82) {
                            return props.toggleNoCodeMatchFilter("no-code-match");
                        };
                        return props.toggleCodeFilter(v7.code + "%");
                    })()),
                    initExpanded: false,
                    disabled: v7.total === 0.0,
                    getItems: function (v8) {
                        return Data_Functor.map(Data_Functor.functorArray)(function ($104) {
                            return TacoList.ExpandableItemWrapper(prepareSubItem($104));
                        })(v7.children);
                    },
                    hasItems: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(v7.children)
                };
            };
        })();
        var classificationItems = TofuHooks.useMemo([ ReactHooksUtils.utf(prepareItem), ReactHooksUtils.utf(v2.value0), ReactHooksUtils.utf(v3.value0) ])(function (v6) {
            var socialExpenseText = function (text) {
                return TacoText.component()()({
                    text: text,
                    color: TacoConstants.GrayDark.value,
                    testId: "classification-total-social-expense-price"
                });
            };
            var classifications = Data_Functor.map(Data_Functor.functorArray)(prepareItem)(v2.value0);
            var _socialExpensePrice = function (v7) {
                return v7.socialExpensePrice;
            };
            var totalSocialExpensePrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(_socialExpensePrice)(v3.value0));
            var socialExpenseRowItem = {
                title: "Sosiaalikulut (ei mukana summissa)",
                titleJSX: Data_Maybe.Just.create(socialExpenseText("Sosiaalikulut (ei mukana summissa)")),
                value: TacoList.JSXValue.create(socialExpenseText(Formatters.formatCurrencyValue(props.project.currency)(totalSocialExpensePrice))),
                disabled: false,
                onClick: Data_Maybe.Nothing.value,
                getItems: function (v7) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false,
                id: "Sosiaalikulut (ei mukana summissa)"
            };
            var socialExpenseRow = (function () {
                if (props.showSocialExpensesInCosts) {
                    return Common.emptyArray;
                };
                return [ socialExpenseRowItem ];
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(classifications)(socialExpenseRow);
        })();
        var loading = v.value1 || loadingClassificationData;
        props.setLoading("ProjectClassificationTable")(loading)();
        return ReactBasicUtils["div'"]("__ProjectClassificationTable")([ Gap.gapX(TacoConstants.M.value)([ React_Basic_DOM_Generated.h3()({
            className: "heading",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Nimikkeist\xf6"))
        }), TacoText.component()()({
            testId: "classification-code",
            text: Data_Newtype.un()(Types_Classification.OrgClassificationCode)(props.project.classification)
        }), TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: "Muokkaa",
            onClick: Data_Maybe.Just.create(v4.value1(function (v6) {
                return true;
            })),
            testId: "edit-project-classifications-button"
        }), TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: (function () {
                if (v5.value0) {
                    return "Piilota";
                };
                return "N\xe4yt\xe4";
            })(),
            onClick: Data_Maybe.Just.create(v5.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            testId: "toggle-show-project-classification-table"
        }) ]), Data_Monoid.guard(React_Basic.monoidJSX)(v5.value0)(TacoList.component({
            items: classificationItems
        })), projectClassificationClassesTreeModal({
            isActive: v4.value0,
            onClose: v4.value1(function (v6) {
                return false;
            }),
            projectClassificationClasses: v.value0,
            projectId: props.projectId,
            existingProjectClassificationClassCodes: existingProjectClassificationClassCodes,
            nonEmptyProjectClassificationClassCodes: nonEmptyProjectClassificationClassCodes
        }) ]);
    };
});
module.exports = {
    projectClassificationTable: projectClassificationTable
};
